/* .App {
  text-align: center;
}

.Form {
  text-align: center;
}

.StepperCustom {
  margin-top: 4%;
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
} */

@font-face {
    font-family: Caecilia;
    src: url("/fonts/CaeciliaLTStd-Roman.woff")
}

.home {
    background-image: url("/home_background_image.png");
    background-position: top;
    background-size: cover;
    height: 87.3vh;
    font-family: Caecilia, sans-serif;
}

.about {
    background-image: url("/home_background_image.png");
    background-position: top;
    background-size: cover;
    height: 87.3vh;
    font-family: Caecilia, sans-serif;
}

.contact-us {
  background-image: url("/home_background_image.png");
  background-position: top;
  background-size: cover;
  height: 87.3vh;
  font-family: Caecilia, sans-serif;
}

.home-about-text {
    font-family: Caecilia, sans-serif;
    text-align: justify;
    color: white;
    padding-top: 5%;
    margin-left: 20%;
    width: 45%;
}

.home-about-text h1{
    font-weight: bold;
    font-size: 1.7em
}

.home-about-text h2, .home-about-text ul{
    font-weight: 400;
    font-size: 1.1em
}

.button-tool {
    width: 260px;
    height: 280px;
    background-color: rgba(0, 103, 127, 0.1);
    text-decoration: none;
    margin-bottom: 4%;
}

.button-tool h4 {
    text-align: center;
	width: 200px;
	font-weight: normal;
	font-size: 1.1em;
	margin-left: auto;
	margin-right: auto;
	font-family: Caecilia, sans-serif;
	color: #484241
}

.hidden-product {
    width: 260px;
    height: 280px;
    background-color: rgba(0, 103, 127, 0.1);
    text-decoration: none;
    margin-bottom: 4%;
    cursor: pointer;
}

.hidden-product h4 {
    text-align: center;
	width: 200px;
	font-weight: normal;
	font-size: 1.1em;
	margin-left: auto;
	margin-right: auto;
	font-family: Caecilia, sans-serif;
	color: #484241
}

.hidden-product p {
  color: transparent;
  font-size: 40 !important;
  text-align: center;
}

.hidden-product:hover {
  background-color: rgb(153, 147, 147) !important;
  cursor: default;
  font-size: 0;
}

.hidden-product:hover p {
  background-color: rgb(153, 147, 147)!important;
  cursor: default;
  font-size: 30px !important;
  color: #eeeeee;
  /* margin-bottom: 20px !important; */
}

.hidden-product:hover h4 {
  background-color: transparent !important;
  font-size: 0 !important;
}

.tool-conclusion {
    font-family:  Caecilia, sans-serif;
    font-size: 1.1em;
    font-weight: normal;
    color:#67676A;
    text-align: justify;
    padding-bottom: 1%;
    line-height: 1.4
}

.projects-overview {
    display: block;
    margin: auto;
    width: 75%;
    font-family:  Caecilia, sans-serif;
}
.projects-overview h1{
    font-weight: bold;
    font-size: 1.7em;
}
.projects-overview h3{
    font-weight: 400;
    font-size: 1.1em
}

.projects-elements{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.project-last-element{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
}

.projects-paper{
    padding:15px;
    margin-bottom: 25px;
    background-color: rgba(0, 103, 127, 0.2) !important;
    font-family:  Caecilia, sans-serif;
}

.projects-container{
    width: 65%;
    margin: auto
}

.databreach-container-text{
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto
}

.projects-container-text{
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto
}

.projects-container-introduction-text{
    font-size:1.1em;
    font-weight: normal;
    color: #67676A;
    padding-bottom: 1%;
    line-height: 1.4;
    text-align: justify !important
}

.projects-container-introduction-text ul .projects-container-introduction-text p{
    font-size:1.1em;
    font-weight: normal;
    color: #67676A;
    padding-bottom: 1%;
    line-height: 1.4;
    text-align: justify !important
}

.projects-container-introduction-subtitle{
    color: #484241;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.3;
    padding-bottom: 2%
}

.projects-question{
    color:#484241;
    text-align: justify;
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.3;
    padding-bottom: 1%;
}

.projects-error{
    color:#484241;
    text-align: center;
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.3;
    padding-bottom: 1%;
}

.projects-heading{
    color: #484241;
    text-align: justify;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1%
}

.clydeco-ai{
    display: flex;
    background-color: #00677F;
    /*min-height: 8vh;*/
    height: 8.7vh;
    align-items: center;
    justify-content: center;
}
.clydeco-ai-text{
    width: auto;
}

.clydeco-ai-text-right{
    width: auto;
    text-align:right
}

.clydeco-ai-text h1{
    font-weight: 500;
    font-family: Caecilia, sans-serif;
    font-size: 40px;
    color: white;
    height: 90%
}

.span-keywords{
    display: inline-block;
    font-weight: bold;
    white-space: nowrap;
    text-align: justify
}

.navbar-box{
    display:flex;
    align-items: center;
    height: 4vh;
    background-color: rgba(0, 103, 127, 0.4);
    font-family: Caecilia, sans-serif;
    justify-content: space-around;
    margin:0
}

.navbar-links{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 5%;
    /*padding-right: 25%;*/
}

.navbar-links a{
    text-decoration: none;
    color: black;
    font-size: 17px;
}

.logo{
    width: 24%;
    margin-left: auto;
    display: block;
    padding-top: 0;
}

.logo-clydeco{
    width: 20%;
    display: block
}

.text-logo {
    display: flex;
    width: 40%;
    margin-left: 0;
    text-align: left;
    margin-top: 1%;
}
.text-logo h2{
    font-family:  Caecilia, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #bfff80
}

.burger{
    display: none;
    cursor: pointer;
    margin: auto;
}

.burger div{
    width: 25px;
    height: 3px;
    background-color: rgb(226,226,226);
    margin: 5px;
    margin-right: 5% !important
}

.find-plot{
    width: 420px !important;
    height: 400px !important;
}

@media screen and (max-width:1200px){
    .logo-clydeco{
        width: 25%
    }
    .logo{
        width: 30%
    }
}

@media screen and (max-width:950px){
    .logo-clydeco{
        width: 35%
    }
    .logo{
        width: 39%
    }
}

@media screen and (max-width:768px){
    body{
        overflow-x: hidden;
    }
    .navbar-box{
        position: absolute;
        right: 0;
        height: 92vh;
        top: 8vh;
        background-position: left top;
        background-color: rgba(162, 193, 204, 0.8);
        width: 36%;
        display: none;
        align-items: center;
        flex-direction: column;
    }
    .navbar-links{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding-right: 0;
        width: 80%;
        align-items: center;
    }
    .burger{
        display:block;
        margin-right: 5%;
    }
    .clydeco-ai-text-right{
        display:none
    }
    .clydeco-ai{
        height: 8vh !important;
    }
    .logo-clydeco{
        width: 50%
    }
    .home{
        height: 92vh
    }
    .about{
        height: 92vh
    }
    .home-about-text{
        width:60%
    }
}

@media screen and (max-width: 414px){
    /*.navbar-box{*/
    /*    height: 90vh;*/
    /*    top: 9.5vh;*/
    /*}*/
    .clydeco-ai-text h1{
        font-size:28px
    }
    .home-about-text{
        width:60%
    }
    .home{
        height: auto;
        min-height: 92vh;
    }
    .about{
        height: auto;
    }
    .contact-us{
        height: 92vh;
    }
    .projects-container{
        width: 85%;
        margin: auto
    }
    .projects-container-text{
        width: 85%
    }
    .navbar-links a{
        font-size: 17px;
    }
    .projects-container-introduction-text{
        font-size: 1.0em;
    }
    .projects-question{
        font-size: 1.0em;
    }
    .tool-conclusion{
        font-size: 1.0em;
    }
    .projects-error{
        font-size: 1.0em;
    }
    .find-plot{
        width: 240px !important;
        height: 260px !important;
    }
    .logo-clydeco{
        width: 70% !important
    }

}

.nav-active{
    display:flex
}